<template>
  <div>
    <client-tabs
      v-if="uDATA.role === 'admin'"
      :user-role="uDATA.role"
      :class="this.$route.name === 'client-profile-edit' ? 'mb-3' : ''"
    />
    <component :is="uDATA === undefined ? 'div' : 'b-card'">
      <b-tabs
        v-if="uDATA"
        pills
        class="proftab"
      >
        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Account</span>
          </template>
          <profile-edit-form
            :user-data="uDATA"
            :client-id="this.$route.query.id"
            class="mt-2 pt-75"
          />
        </b-tab>
      </b-tabs>
    </component>
  </div>
</template>

<script>
import {
  BTab, BTabs, BCard, BLink,
} from 'bootstrap-vue'
import ClientTabs from '../clients/ClientTabs.vue'
import ProfileEditForm from './ProfileEditForm.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BLink,

    ProfileEditForm,
    ClientTabs,
  },
  setup() {
    const uDATA = JSON.parse(localStorage.getItem('userData'))

    return {
      pageTitle: 'Edit Client Profile',
      uDATA,
    }
  },
}
</script>

<style>
.proftab ul[role=tablist]{
  display:none;
}
</style>
