/* eslint-disable no-return-assign */
import axios from '@axios'
import Vue from 'vue'
import { reactive } from '@vue/composition-api'

export default {
  namespaced: true,
  state: reactive({
    clientInfo: [],
    currentCaregiver: false,
  }),
  mutations: {
    SET_CLIENT_INFO: (state, payload) => (state.clientInfo = payload),
    SET_PROF_PIC: (state, payload) => (state.clientInfo.profile_picture = payload),
    SET_CURRENT_CAREGIVER: (state, payload) => (state.currentCaregiver = payload),
  },
  getters: {
    // getItems: state => state.items,
  },
  actions: {
    getClientData(ctx, cid) {
      const uData = JSON.parse(localStorage.getItem('userData'))
      const accessToken = localStorage.getItem('accessToken')
      let param = {
        userData: uData,
      }
      if (uData.role === 'admin') {
        param = {
          clientId: cid,
        }
      }

      return new Promise((resolve, reject) => {
        axios
          .post(`${Vue.prototype.$apiUrl2}ClientDashboard/fetchClientData`, {
            param,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    saveData(ctx, formData) {
      const accessToken = localStorage.getItem('accessToken')
      return new Promise((resolve, reject) => {
        axios
          .post(`${Vue.prototype.$apiUrl2}ClientDashboard/saveClientData`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-type': 'multipart/form-data',
              },
            })
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    changeProfilePic(ctx, formData) {
      const accessToken = localStorage.getItem('accessToken')
      return new Promise((resolve, reject) => {
        axios
          .post(`${Vue.prototype.$apiUrl2}ClientDashboard/changeProfilePic`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-type': 'multipart/form-data',
              },
            })
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    removeProfPic(ctx, formData) {
      const accessToken = localStorage.getItem('accessToken')
      return new Promise((resolve, reject) => {
        axios
          .post(`${Vue.prototype.$apiUrl2}ClientDashboard/removeProfPic`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-type': 'multipart/form-data',
              },
            })
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    /* request: async () => {
      const formData = new FormData()
      const data = JSON.stringify({
        action: 'get-Shift',
        userId: JSON.parse(localStorage.userData).id,
      })
      formData.append('data', data)
      const accessToken = localStorage.getItem('accessToken')
      try {
        const response = await axios.post(`${Vue.prototype.$apiUrl2}ClientDashboard/fetchClientShiftInfo`, formData,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
        return response
      } catch (error) {
        return error
      }
    }, */
  },
}
