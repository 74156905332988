<template>
  <div class="profile_edit">
    <!-- Media -->
    <b-media class="mb-4">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="clientInfo.profile_picture"
          :text="avatarText(clientInfo.clientName).slice(0,2)"
          size="90px"
          variant="primary"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ clientInfo.clientName }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button
          variant="secondary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            accept="image/*"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          v-b-modal.modal-removeprofpic
          variant="outline-dark"
          class="ml-1"
          :disabled="clientInfo.profile_picture !== '' ? false:true"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </b-media>

    <!-- Header: Basic Information -->
    <div class="d-flex">
      <template>
        <b-avatar
          rounded
          variant="primary"
          size="25"
          style="background-color: white;"
        >
          <img
            src="@/assets/images/icons/basic_info_icon.svg"
            width="25px"
            height="25px"
          >
        </b-avatar>
      </template>
      <h4
        class="mb-0 ml-50 gray-1"
        style="line-height:1.5em"
      >
        Basic Information
      </h4>
    </div>

    <!-- Basic Info: Input Fields -->
    <validation-observer ref="simpleRules">
      <b-form class="mt-1">
        <b-row>

          <!-- Field: First Name -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="First Name *"
              label-for="firstname"
            >
              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="required"
              >
                <b-form-input
                  id="firstname"
                  v-model="clientInfo.firstname"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Middle Name -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Middle Name"
              label-for="middlename"
            >
              <b-form-input
                id="middlename"
                v-model="clientInfo.middlename"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Email -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Last Name *"
              label-for="lastname"
            >
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                rules="required"
              >
                <b-form-input
                  id="lastname"
                  v-model="clientInfo.lastname"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <hr class="my-2 mb-3">

    <!-- Header: Address Information -->
    <div class="d-flex">
      <feather-icon
        icon="MapPinIcon"
        size="19"
        class="gray-1"
      />
      <h4 class="mb-0 ml-50 gray-1">
        Address Information
      </h4>
    </div>

    <!-- Address Info: Input Fields -->
    <b-form class="mt-1">
      <b-row>

        <!-- Field: Address Line 1 -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Address Line 1"
            label-for="addressline1"
          >
            <b-form-input
              id="addressline1"
              v-model="clientInfo.addressLine1"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Address Line 2 -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Address Line 2"
            label-for="addressline2"
          >
            <b-form-input
              id="addressline2"
              v-model="clientInfo.addressLine2"
            />
          </b-form-group>
        </b-col>

        <!-- Field: City -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="City"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="clientInfo.city"
            />
          </b-form-group>
        </b-col>

        <!-- Field: State -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="State"
            label-for="state"
          >
            <v-select
              v-model="clientInfo.state"
              :options="stateOptions"
              input-id="state"
              placeholder="Select or Type State"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Postal Code -->
        <b-col
          cols="12"
          md="4"
        >
          <validation-observer ref="simpleRules">
            <b-form-group
              label="Postal Code"
              label-for="postalcode"
            >
              <validation-provider
                #default="{ errors }"
                rules="required|integer"
                name="Postal Code"
              >
                <b-form-input
                  id="postalcode"
                  v-model="clientInfo.postalCode"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </validation-observer>
        </b-col>
      </b-row>
    </b-form>

    <hr class="my-2 mb-3">

    <!-- Header: Contact Information -->
    <div class="d-flex">
      <feather-icon
        icon="PhoneIcon"
        size="19"
        class="gray-1"
      />
      <h4 class="mb-0 ml-50 gray-1">
        Contact Information
      </h4>
    </div>

    <!-- Contact Info: Input Fields -->
    <b-form class="mt-1">
      <b-row>

        <!-- Field: Home Phone -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Home Phone"
            label-for="homephone"
          >
            <validation-provider
              #default="{ errors }"
              name="Home Phone"
              :rules="clientInfo.primaryPhone === 'homephone' ? 'required' : ''"
            >
              <span
                class="row col p-0 m-0"
              >
                <b-input-group
                  class="col mr-2 p-0"
                >
                  <b-input-group-prepend is-text>
                    US (+1)
                  </b-input-group-prepend>
                  <b-form-input
                    id="homephone"
                    v-model="clientInfo.homePhone"
                    v-mask="['###-###-####']"
                    :state="errors.length > 0 ? false : null"
                    type="tel"
                  />
                </b-input-group>
                <b-form-checkbox
                  v-model="clientInfo.primaryPhone"
                  value="homephone"
                  class="col-md-3 align-self-center"
                >
                  Primary
                </b-form-checkbox>
              </span>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Field: Mobile Phone -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Mobile Phone"
            label-for="mobilephone"
          >
            <validation-provider
              #default="{ errors }"
              name="Mobile Phone"
              :rules="clientInfo.primaryPhone === 'mobilephone' ? 'required' : ''"
            >
              <span
                class="row col p-0 m-0"
              >
                <b-input-group
                  class="col mr-2 p-0"
                >
                  <b-input-group-prepend is-text>
                    US (+1)
                  </b-input-group-prepend>
                  <b-form-input
                    id="mobilephone"
                    v-model="clientInfo.mobilePhone"
                    v-mask="['###-###-####']"
                    :state="errors.length > 0 ? false : null"
                    type="tel"
                  />
                </b-input-group>
                <b-form-checkbox
                  v-model="clientInfo.primaryPhone"
                  value="mobilephone"
                  class="col-md-3 align-self-center"
                >
                  Primary
                </b-form-checkbox>
              </span>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Field: Work Phone -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Work Phone"
            label-for="workphone"
          >
            <validation-provider
              #default="{ errors }"
              name="Mobile Phone"
              :rules="clientInfo.primaryPhone === 'workphone' ? 'required' : ''"
            >
              <span
                class="row col p-0 m-0"
              >
                <b-input-group
                  class="col mr-2 p-0"
                >
                  <b-input-group-prepend is-text>
                    US (+1)
                  </b-input-group-prepend>
                  <b-form-input
                    id="workphone"
                    v-model="clientInfo.workPhone"
                    v-mask="['###-###-####']"
                    :state="errors.length > 0 ? false : null"
                    type="tel"
                  />
                </b-input-group>
                <b-form-checkbox
                  v-model="clientInfo.primaryPhone"
                  value="workphone"
                  class="col-md-3 align-self-center"
                >
                  Primary
                </b-form-checkbox>
              </span>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Field: Email Address-->
        <b-col
          cols="12"
          md="4"
        >
          <validation-observer ref="simpleRules">
            <b-form-group
              label="Email Address"
              label-for="emailaddress"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="emailaddress"
                  v-model="clientInfo.emailAddress"
                  type="email"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </validation-observer>
        </b-col>
      </b-row>
    </b-form>

    <hr class="my-2 mb-3">

    <!-- Header: Other Information -->
    <div class="d-flex">
      <template>
        <b-avatar
          rounded
          variant="primary"
          size="25"
          style="background-color: white;"
        >
          <img
            src="@/assets/images/icons/basic_info_icon.svg"
            width="25px"
            height="25px"
          >
        </b-avatar>
      </template>
      <h4
        class="mb-0 ml-50 gray-1"
        style="line-height:1.5em;"
      >
        Other Information
      </h4>
    </div>

    <!-- Other Information: Input Fields -->
    <b-form class="mt-1 mb-4">
      <b-row>

        <!-- Field: SSN -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="SSN"
            label-for="ssn"
          >
            <b-form-input
              id="ssn"
              v-model="clientInfo.sss"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Location -->
        <b-col
          cols="12"
          md="8"
        >
          <b-form-group
            label="Location"
            label-for="location"
          >
            <b-form-input
              id="location"
              v-model="clientInfo.location"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Referrer -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Referrer"
            label-for="referrer"
          >
            <b-form-input
              id="referrer"
              v-model="clientInfo.referrer"
            />
          </b-form-group>
        </b-col>

        <!-- Field: External ID -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="External ID"
            label-for="externalid"
          >
            <b-form-input
              id="externalid"
              v-model="clientInfo.externalId"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="secondary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click.prevent="validationForm"
    >
      Save Changes
    </b-button>
    <b-button
      variant="outline-dark"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="fetchMyData"
    >
      Reset
    </b-button>

    <!-- remove profile modal  -->
    <b-modal
      id="modal-removeprofpic"
      title="Remove Photo"
      centered
    >
      <div class="mx-0.5 my-1 text-centered">
        <span>
          Are you sure you want to remove this photo?
        </span>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-dark"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="danger"
          @click="removeProfPic(); ok()"
        >
          Remove
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, integer } from '@validations'
import Swal from 'sweetalert2'
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormCheckboxGroup, BModal,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { mask } from 'vue-the-mask'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import store from '@/store'
import clientDashboardStoreModule from './clientDashboardStoreModule.js'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    BFormCheckboxGroup,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
  },
  directives: {
    mask,
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    clientId: {
      type: String,
      default: '',
      required: false,
    },
  },
  setup(props) {
    const CLIENT_DASHBOARD_APP_STORE_MODULE_NAME = 'client_dashboard'

    // Register module
    if (!store.hasModule(CLIENT_DASHBOARD_APP_STORE_MODULE_NAME)) {
      store.registerModule(CLIENT_DASHBOARD_APP_STORE_MODULE_NAME, clientDashboardStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_DASHBOARD_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(CLIENT_DASHBOARD_APP_STORE_MODULE_NAME)
      }
    })

    const stateOptions = [
      'AK', 'AL', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY',
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    /* eslint-disable global-require */

    const uDATA = JSON.parse(localStorage.getItem('userData')) // update this to clientInfo
    const clientInfo = ref(computed(() => store.state.client_dashboard.clientInfo))
    const simpleRules = ref(null)

    /* eslint-disable */
    const imageChecker = () => {
      if (refInputEl.value.files.length > 0) {
        const image = refInputEl.value.files[0]
        const fileSize = image.size
        var fileMb = fileSize / 1024 ** 2
      } else {
        return false
      }

      if (fileMb > 2) {
        // showMessage('Image size is too large. Max of 2mb only.', 'error', 'center')
        Swal.fire({
          icon: 'error',
          title: 'File Was Not Uploaded',
          text: 'Your file is too large. The allowed file size should not exceed 1MB.',
          showConfirmButton: false,
          timer: 2500,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return false
      }

      return true
    }
    /* eslint-enable */

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // to upload image
      if (refInputEl.value.files && imageChecker()) {
        const formData = new FormData()
        // console.log(refInputEl.value.files)

        formData.append('files', refInputEl.value.files[0])
        formData.append('clientid', uDATA.role === 'admin' ? props.clientId : clientInfo.value.id)

        store.dispatch('client_dashboard/changeProfilePic', formData)
          .then(response => {
            // console.log(response.data.response.data)
            store.commit('client_dashboard/SET_PROF_PIC', response.data.response.data)
            // eslint-disable-next-line no-param-reassign
            props.userData.avatar = base64

            Swal.fire({
              icon: 'success',
              title: 'Changed Image Succesfully',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            // emit('toast-notif', 'Success', `${f.name} added!`, 'CheckIcon', 'success')
          })
          // eslint-disable-next-line
          .catch(error => {
            console.log(error)
            Swal.fire({
              icon: 'error',
              title: 'File Was Not Uploaded',
              text: 'There was a problem uploading the image. Please try again.',
              showConfirmButton: false,
              timer: 2500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            /* Enables btn */
            // btnDisabled.value = false
            // console.log(error)
            // emit('toast-notif', 'Error', '', 'AlertTriangleIcon', 'danger')
          })
      }
    })

    // *===============================================---*
    // *--------- RETRIEVE DATA ---------------------------*
    // *===============================================---*
    const fetchMyData = () => {
      store.dispatch('client_dashboard/getClientData', uDATA.role === 'admin' ? props.clientId : {})
        .then(response => {
          // console.log('some response message', response.data.response.data[0])
          store.commit('client_dashboard/SET_CLIENT_INFO', response.data.response.data[0])
        })
        .catch(() => {
          // console.log('some error message', er)
        })
    }
    fetchMyData()

    // *===============================================---*
    // *--------- REMOVE PIC ---------------------------*
    // *===============================================---*
    const removeProfPic = () => {
      const formData = new FormData()
      formData.append('clientid', clientInfo.value.id)
      store.dispatch('client_dashboard/removeProfPic', formData)
        .then(() => {
          store.commit('client_dashboard/SET_PROF_PIC', '')
          Swal.fire({
            icon: 'success',
            title: 'Removed Image Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(() => {
          // console.log('some error message', er)
        })
    }

    // *===============================================---*
    // *--------- SAVE DATA ------------------------------*
    // *===============================================---*
    const onSaveData = () => {
      const uData = localStorage.getItem('userData')
      const uID = JSON.parse(uData)
      // console.log('user data', )
      const formData = new FormData()
      const data = JSON.stringify({
        clientid: uDATA.role === 'admin' ? props.clientId : uID.clientId,
        firstname: clientInfo.value.firstname,
        middlename: clientInfo.value.middlename,
        lastname: clientInfo.value.lastname,
        addressline1: clientInfo.value.addressLine1,
        addressline2: clientInfo.value.addressLine2,
        city: clientInfo.value.city,
        state: clientInfo.value.state,
        postalcode: clientInfo.value.postalCode,
        homephone: clientInfo.value.homePhone,
        mobilephone: clientInfo.value.mobilePhone,
        workphone: clientInfo.value.workPhone,
        primaryphone: clientInfo.value.primaryPhone,
        emailaddress: clientInfo.value.emailAddress,
        sss: clientInfo.value.sss,
        location: clientInfo.value.location,
        referrer: clientInfo.value.referrer,
        externalid: clientInfo.value.externalId,
      })
      formData.append('data', data)
      /* eslint-disable */
      /* for (let value of formData.values()) {
        console.log(value)
      } */
      /* eslint-enable */
      store.dispatch('client_dashboard/saveData', formData)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Updated Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          // emit('fetch-my-data')
        })
        .catch(error => {
          console.error(error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1500,
          })
          // console.log('error', error)
        })
    }

    const validationForm = () => {
      simpleRules.value.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          // alert('form submitted!')
          onSaveData()
          // console.log('ref.simpleRules', simpleRules.value)
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Make sure to fill up  the required fields',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
    }

    return {
      avatarText,
      uDATA,
      clientInfo,
      stateOptions,
      fetchMyData,
      removeProfPic,
      onSaveData,
      validationForm,
      required,
      email,
      integer,
      simpleRules,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  methods: {
    /* isOtherSelected(val) {
      console.log(val)
      if (val !== 'workphone') {
        this.clientInfo.primaryPhone = ''
      }
    }, */
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.profile_edit{
  margin-top: 0 !important;
}

/* .profile_edit hr{
  border-width: 1px;
} */

.card_profile tr{
  border-bottom: none !important;
}
</style>
